<template>
  <div id="softdrink-totals" v-if="user && companies && publishedMonths">
    <v-card class="elevation-0">
      <v-card-title>
        <v-col cols="4" lg="3">
          <v-menu
            ref="startMenu"
            v-model="monthStartMenu"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="monthStart"
                label="Startmåned"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="monthStart"
              type="month"
              no-title
              scrollable
              :min="minMonth"
              :max="maxMonth"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" lg="3">
          <v-menu
            ref="endMenu"
            v-model="monthEndMenu"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="monthEnd"
                label="Slutmåned"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="monthEnd"
              type="month"
              no-title
              scrollable
              :min="minMonth"
              :max="maxMonth"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" lg="3">
          <v-select
            v-if="user.company.admin"
            :items="companies"
            label="Bryggeri"
            item-text="name"
            item-value="_id"
            v-model="company"
          ></v-select>
        </v-col>
        <v-col
          v-if="!result"
          cols="12"
          class="font-weight-regular text-center mt-6"
          >Ingen resultater for den valgte periode</v-col
        >
        <v-col v-if="result" cols="8" lg="9" class="font-weight-regular"
          >Viser total for <strong>{{ resultCompanies }}</strong></v-col
        >
        <v-col v-if="result" cols="4" lg="3" class="text-right no-print">
          <v-btn @click="printClick"
            ><v-icon left>mdi-printer</v-icon>Udskriv</v-btn
          >
          <v-btn @click="excelClick" class="ml-3"
            ><v-icon left>mdi-file-excel-outline</v-icon>Excel</v-btn
          >
        </v-col>
      </v-card-title>
      <v-card-text>
        <table class="total-table" v-if="result" ref="table">
          <tr>
            <th class="left" colspan="1">Kategori</th>
            <th class="left" colspan="2">Total</th>
            <th class="left" colspan="2">Heraf sukkerfri</th>
            <th class="left" colspan="2">{{ resultCompany.name }}</th>
          </tr>
          <tr class="bb">
            <th class="left">Samlet salg af</th>
            <th>Liter</th>
            <th>%</th>
            <th>Liter</th>
            <th>%</th>
            <th>Liter</th>
            <th>%</th>
          </tr>

          <tr
            v-for="(cells, index) in result.laskedrikke"
            :key="`row-${index}`"
          >
            <td class="left br">{{ cells[0] }}</td>
            <td>{{ cells[1] }}</td>
            <td class="br">{{ cells[2] }}</td>
            <td>{{ cells[3] }}</td>
            <td class="br">{{ cells[4] }}</td>
            <td>{{ cells[5] }}</td>
            <td>{{ cells[6] }}</td>
          </tr>

          <tr class="font-weight-bold">
            <td class="left br">{{ result.totals.laskedrikke[0] }}</td>
            <td>{{ result.totals.laskedrikke[1] }}</td>
            <td class="br">{{ result.totals.laskedrikke[2] }}</td>
            <td>{{ result.totals.laskedrikke[3] }}</td>
            <td class="br">{{ result.totals.laskedrikke[4] }}</td>
            <td>{{ result.totals.laskedrikke[5] }}</td>
            <td>{{ result.totals.laskedrikke[6] }}</td>
          </tr>

          <tr class="bb"></tr>

          <tr v-for="(cells, index) in result.hvidevande" :key="`row-${index}`">
            <td class="left br">{{ cells[0] }}</td>
            <td>{{ cells[1] }}</td>
            <td class="br">{{ cells[2] }}</td>
            <td>{{ cells[3] }}</td>
            <td class="br">{{ cells[4] }}</td>
            <td>{{ cells[5] }}</td>
            <td>{{ cells[6] }}</td>
          </tr>

          <tr class="font-weight-bold">
            <td class="left br">{{ result.totals.hvidevande[0] }}</td>
            <td>{{ result.totals.hvidevande[1] }}</td>
            <td class="br">{{ result.totals.hvidevande[2] }}</td>
            <td>{{ result.totals.hvidevande[3] }}</td>
            <td class="br">{{ result.totals.hvidevande[4] }}</td>
            <td>{{ result.totals.hvidevande[5] }}</td>
            <td>{{ result.totals.hvidevande[6] }}</td>
          </tr>

          <tr class="bb"></tr>

          <tr
            v-for="(cells, index) in result.andredrikke"
            :key="`row-${index}`"
          >
            <td class="left br">{{ cells[0] }}</td>
            <td>{{ cells[1] }}</td>
            <td class="br">{{ cells[2] }}</td>
            <td>{{ cells[3] }}</td>
            <td class="br">{{ cells[4] }}</td>
            <td>{{ cells[5] }}</td>
            <td>{{ cells[6] }}</td>
          </tr>

          <tr class="font-weight-bold">
            <td class="left br">{{ result.totals.andredrikke[0] }}</td>
            <td>{{ result.totals.andredrikke[1] }}</td>
            <td class="br">{{ result.totals.andredrikke[2] }}</td>
            <td>{{ result.totals.andredrikke[3] }}</td>
            <td class="br">{{ result.totals.andredrikke[4] }}</td>
            <td>{{ result.totals.andredrikke[5] }}</td>
            <td>{{ result.totals.andredrikke[6] }}</td>
          </tr>

          <tr class="bb"></tr>

          <tr class="font-weight-bold">
            <td class="left br">{{ result.totals.total[0] }}</td>
            <td>{{ result.totals.total[1] }}</td>
            <td class="br">{{ result.totals.total[2] }}</td>
            <td>{{ result.totals.total[3] }}</td>
            <td class="br">{{ result.totals.total[4] }}</td>
            <td>{{ result.totals.total[5] }}</td>
            <td>{{ result.totals.total[6] }}</td>
          </tr>

          <tr class="bb"></tr>

          <tr class="font-weight-bold">
            <td class="left br">{{ result.ontrade.ontrade[0] }}</td>
            <td>{{ result.ontrade.ontrade[1] }}</td>
            <td class="br">{{ result.ontrade.ontrade[2] }}</td>
            <td>{{ result.ontrade.ontrade[3] }}</td>
            <td class="br">{{ result.ontrade.ontrade[4] }}</td>
            <td>{{ result.ontrade.ontrade[5] }}</td>
            <td>{{ result.ontrade.ontrade[6] }}</td>
          </tr>

          <tr class="bb"></tr>

          <tr class="bb"></tr>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "../api/api";
import { mapState, mapActions } from "vuex";
import DateTime from "../utils/DateTime";
import { saveAs } from "file-saver";

export default {
  name: "SoftdrinkTotals",
  components: {},
  data() {
    return {
      monthStart: null,
      monthStartMenu: false,
      monthEnd: null,
      monthEndMenu: false,
      company: null,
      result: null,
      resultCompanies: null,
      resultCompany: null,
    };
  },
  mounted() {
    this.loadPublishedMonths();
    this.loadCompanies();
  },
  watch: {
    companies(newVal) {
      this.company = newVal[0]._id;
    },
    softdrinkPublishedMonth(newVal) {
      const yearMonth = DateTime.yearMonthToYearmonthstring(
        newVal.endyear,
        newVal.endmonth
      );
      this.monthStart = yearMonth;
      this.monthEnd = yearMonth;
    },
    monthStart(newVal, oldVal) {
      if (typeof newVal === "string" && newVal !== oldVal) {
        this.filterChange();
      }
    },
    monthEnd(newVal, oldVal) {
      if (typeof newVal === "string" && newVal !== oldVal) {
        this.filterChange();
      }
    },
    company(newVal, oldVal) {
      if (typeof newVal === "string" && newVal !== oldVal) {
        this.filterChange();
      }
    },
  },

  methods: {
    ...mapActions(["loadCompanies", "loadPublishedMonths"]),
    filterChange() {
      if (this.monthStart && this.monthEnd && this.company) {
        this.loadTotals();
      }
    },
    loadTotals() {
      const start = DateTime.yearmonthstringToYearMonth(this.monthStart);
      const startyear = start[0];
      const startmonth = start[1];

      const end = DateTime.yearmonthstringToYearMonth(this.monthEnd);
      const endyear = end[0];
      const endmonth = end[1];

      const company = this.company;

      const query = {
        company,
        startyear,
        startmonth,
        endyear,
        endmonth,
      };
      api.getSoftdrinkTotals(this.token, query).then((response) => {
        this.result = response.data.table;
        this.resultCompanies = response.data.companies.join(", ");
        this.resultCompany = response.data.company;
      });
    },
    printClick() {
      window.print();
    },
    excelClick() {
      const table = this.$refs.table;
      let tableString = "";
      table.childNodes.forEach((row) => {
        let rowString = "";
        const values = [];
        row.childNodes.forEach((cell) => {
          values.push(cell.innerText);
          const colspan = cell.getAttribute("colspan");
          if (colspan) {
            for (let i = 1; i < colspan; ++i) {
              values.push("");
            }
          }
          rowString = values.join(";");
        });
        tableString += `${rowString}\n`;
      });
      const blob = new Blob([tableString], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, `Læskedrikke-Total-${this.monthStart}-${this.monthEnd}.csv`);
    },
  },
  computed: {
    ...mapState(["loaded", "user", "token", "companies", "publishedMonths"]),
    softdrinkPublishedMonth() {
      if (this.publishedMonths) {
        return this.publishedMonths.find((month) => {
          return month.type === "softdrink";
        });
      }

      return null;
    },
    minMonth() {
      const min = DateTime.yearMonthToYearmonthstring(
        this.softdrinkPublishedMonth.startyear,
        this.softdrinkPublishedMonth.startmonth
      );

      return this.user.company.admin ? null : min;
    },
    maxMonth() {
      const max = DateTime.yearMonthToYearmonthstring(
        this.softdrinkPublishedMonth.endyear,
        this.softdrinkPublishedMonth.endmonth
      );

      return this.user.company.admin ? null : max;
    },
  },
};
</script>
<style>
.total-table {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-spacing: 0;
  border-collapse: collapse;
}
.total-table > tr > th {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px;
}
.total-table > tr > td {
  text-align: right;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.total-table > tr > th.left,
.total-table > tr > td.left {
  text-align: left;
}
.total-table > tr.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.total-table > tr > td.br {
  border-right: 1px solid rgba(0, 0, 0, 0.5);
}
@media print {
  .v-navigation-drawer,
  .v-app-bar,
  .no-print {
    display: none;
  }
  .v-main {
    padding: 0 !important;
  }
  .total-table {
    font-size: 9pt;
  }
  .total-table > tr > th,
  .total-table > tr > td {
    padding: 2pt;
  }
}
</style>
